function sendInBlueSubmit(email) {
  fetch("/.netlify/functions/send-in-blue", {
    method: "post",
    body: JSON.stringify({
      email,
    }),
  })
    // .then(function(response) {
    //   return response.json()
    // })
    .then(function (data) {
      // console.log('data from function', data)
      const messageDiv = document.getElementById("message");
      // messageDiv.classList.add("highlight");
      messageDiv.textContent = "Thank you, we'll be in touch soon.";
    });
  console.log(email);
}

function facebookConversionApi(email, ip, ua) {
  fetch("/.netlify/functions/facebook-conversions", {
    method: "post",
    body: JSON.stringify({
      email,
      ip,
      ua,
    }),
  });
}

async function getIp() {
  try {
    const res = await fetch("/.netlify/functions/ip");
    const json = await res.json();
    // const ip = await json.ip
    // console.log(json.ip);
    return json.ip;
    // document.querySelector('p.text tt').innerHTML = json.ip
  } catch (error) {
    // document.querySelector('p.text').innerHTML =
    // "Well, that's embarrassing. I've returned an error <small>(I've logged it to the console)</small>. Whoops."
    console.log(error);
  }
}

async function handleFormClick(event) {
  event.preventDefault();
  const signUpFormContents = signUpForm.querySelector(
    'input[type="email"]'
  ).value;
  sendInBlueSubmit(signUpFormContents);
  const ua = navigator.userAgent;
  const ip = await getIp();
  facebookConversionApi(signUpFormContents, ip, ua);
  if (cc.getUserPreferences().accepted_categories.includes('targeting')) {
    fbq('track', 'Subscribe', {}, {eventID: `FI_${new Date().getTime()}`});
  } else {
    console.log('fqp browser event not sent as user has not consented')
  }
}

var signUpForm = document.getElementById("email");
if (signUpForm) {
  signUpForm.addEventListener("submit", handleFormClick);
}
